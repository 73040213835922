import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import { permission, moduleMenu } from "@/axios/index";
import { authorityfun, moduleMenufun } from "@/utils/common";

import { showNotify } from "vant";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: () => import("../views/resetPassword.vue"),
  },
  {
    path: "/noticeList",
    name: "noticeList",
    component: () => import("../views/noticeList.vue"),
  },
  {
    path: "/applyforList",
    name: "applyforList",
    component: () => import("../views/applyforList.vue"),
  },
  {
    path: "/applyforLists",
    name: "applyforLists",
    component: () => import("../views/applyforLists.vue"),
  },
  {
    path: "/homepagapplyforLists",
    name: "homepagapplyforLists",
    component: () => import("../views/homepagapplyforLists.vue"),
  },
  {
    path: "/noticeLists",
    name: "noticeLists",
    component: () => import("../views/noticeLists.vue"),
  },
  {
    path: "/newApplys",
    name: "newApplys",
    component: () => import("../views/newApplys.vue"),
  },
  {
    path: "/addnewApplys",
    name: "addnewApplys",
    component: () => import("../views/addnewApplys.vue"),
  },
  {
    path: "/approveList",
    name: "approveList",
    component: () => import("../views/approveList.vue"),
  },
  {
    path: "/approveList2",
    name: "approveList2",
    component: () => import("../views/approveList2.vue"),
  },
  {
    path: "/approveLists",
    name: "approveLists",
    component: () => import("../views/approveLists.vue"),
  },
  {
    path: "/homepageapproveLists",
    name: "homepageapproveLists",
    component: () => import("../views/homepageapproveLists.vue"),
  },
  {
    path: "/staGing",
    name: "staGing",
    component: () => import("../views/staGing.vue"),
  },
  {
    path: "/myCenter",
    name: "myCenter",
    component: () => import("../views/myCenter.vue"),
  },
  {
    path: "/smartLogistics",
    name: "smartLogistics",
    component: () => import("../views/smartLogistics.vue"),
  },
  {
    path: "/warehouseGoods",
    name: "warehouseGoods",
    component: () => import("../views/warehouseGoods.vue"),
  },
  {
    path: "/warehouseMange",
    name: "warehouseMange",
    component: () => import("../views/warehouseMange.vue"),
  },
  {
    path: "/alarmList",
    name: "alarmList",
    component: () => import("../views/alarmList.vue"),
  },
  {
    path: "/alarmList2",
    name: "alarmList2",
    component: () => import("../views/alarmList2.vue"),
  },
  {
    path: "/rankingList",
    name: "rankingList",
    component: () => import("../views/rankingList.vue"),
  },
  {
    path: "/houseMaster",
    name: "houseMaster",
    component: () => import("../views/houseMaster.vue"),
  },
  {
    path: "/personalinformation",
    name: "personalinformation",
    component: () => import("../views/personalinformation.vue"),
  },
  {
    path: "/updatepassword",
    name: "updatepassword",
    component: () => import("../views/updatepassword.vue"),
  },
  {
    path: "/updatepasswordd",
    name: "updatepasswordd",
    component: () => import("../views/updatepasswordd.vue"),
  },
  {
    path: "/updatepassworddd",
    name: "updatepassworddd",
    component: () => import("../views/updatepassworddd.vue"),
  },
  {
    path: "/resetpassworda",
    name: "resetpassworda",
    component: () => import("../views/resetpassworda.vue"),
  },
  {
    path: "/resetpasswordaa",
    name: "resetpasswordaa",
    component: () => import("../views/resetpasswordaa.vue"),
  },
  {
    path: "/Problemfeedback",
    name: "Problemfeedback",
    component: () => import("../views/Problemfeedback.vue"),
  },
  {
    path: "/Problemfeedbackk",
    name: "Problemfeedbackk",
    component: () => import("../views/Problemfeedbackk.vue"),
  },
  {
    path: "/Messagenotification",
    name: "Messagenotification",
    component: () => import("../views/Messagenotification.vue"),
  },
  {
    path: "/notReturnbed",
    name: "notReturnbed",
    component: () => import("../views/notReturnbed.vue"),
  },
  {
    path: "/dormData",
    name: "dormData",
    component: () => import("../views/dormData.vue"),
  },
  {
    path: "/teacherData",
    name: "teacherData",
    component: () => import("../views/teacherData.vue"),
  },
  {
    path: "/studentDetil",
    name: "studentDetil",
    component: () => import("../views/studentDetil.vue"),
  },
  {
    path: "/parentPage",
    name: "parentPage",
    component: () => import("../views/parentPage.vue"),
  },
  {
    path: "/manageData",
    name: "manageData",
    component: () => import("../views/manageData.vue"),
  },
  {
    path: "/leaveData",
    name: "leaveData",
    component: () => import("../views/leaveDate.vue"),
  },
  {
    path: "/bindRole",
    name: "bindRole",
    component: () => import("../views/bindRole.vue"),
  },
  {
    path: "/personnelList",
    name: "personnelList",
    component: () => import("../views/personnelList.vue"),
  },
  {
    path: "/studenTinformation",
    name: "studenTinformation",
    component: () => import("../views/studenTinformation.vue"),
  },
  {
    path: "/classCard",
    name: "classCard",
    component: () => import("../views/classCard.vue"),
  },
  {
    path: "/banpaiData",
    name: "banpaiData",
    component: () => import("../views/banpaiData.vue"),
  },
  {
    path: "/messageData",
    name: "messageData",
    component: () => import("../views/messageData.vue"),
  },
  {
    path: "/banpainotification",
    name: "banpainotification",
    component: () => import("../views/banpainotification.vue"),
  },
  {
    path: "/addbanpainotification",
    name: "addbanpainotification",
    component: () => import("../views/addbanpainotification.vue"),
  },
  {
    path: "/goodstudent",
    name: "goodstudent",
    component: () => import("../views/goodstudent.vue"),
  },
  {
    path: "/addgoodstudent",
    name: "addgoodstudent",
    component: () => import("../views/addgoodstudent.vue"),
  },
  {
    path: "/studentswork",
    name: "studentswork",
    component: () => import("../views/studentswork.vue"),
  },
  {
    path: "/addstudentswork",
    name: "addstudentswork",
    component: () => import("../views/addstudentswork.vue"),
  },
  {
    path: "/massageDetail",
    name: "massageDetail",
    component: () => import("../views/massageDetail.vue"),
  },
  {
    path: "/myClass",
    name: "myClass",
    component: () => import("../views/myClass.vue"),
  },
  {
    path: "/classvideo",
    name: "classvideo",
    component: () => import("../views/classvideo.vue"),
  },
  {
    path: "/addclassvideo",
    name: "addclassvideo",
    component: () => import("../views/addclassvideo.vue"),
  },
  {
    path: "/myclassData",
    name: "myclassData",
    component: () => import("../views/myclassData.vue"),
  },
  {
    path: "/classmien",
    name: "classmien",
    component: () => import("../views/classmien.vue"),
  },
  {
    path: "/addclassmien",
    name: "addclassmien",
    component: () => import("../views/addclassmien.vue"),
  },
  {
    path: "/schoolvideo",
    name: "schoolvideo",
    component: () => import("../views/schoolvideo.vue"),
  },
  {
    path: "/addschoolvideo",
    name: "addschoolvideo",
    component: () => import("../views/addschoolvideo.vue"),
  },
  {
    path: "/schoolmien",
    name: "schoolmien",
    component: () => import("../views/schoolmien.vue"),
  },
  {
    path: "/addschoolmien",
    name: "addschoolmien",
    component: () => import("../views/addschoolmien.vue"),
  },
  {
    path: "/imagescreensaver",
    name: "imagescreensaver",
    component: () => import("../views/imagescreensaver.vue"),
  },
  {
    path: "/addimagescreensaver",
    name: "addimagescreensaver",
    component: () => import("../views/addimagescreensaver.vue"),
  },
  {
    path: "/videoscreensaver",
    name: "videoscreensaver",
    component: () => import("../views/videoscreensaver.vue"),
  },
  {
    path: "/addvideoscreensaver",
    name: "addvideoscreensaver",
    component: () => import("../views/addvideoscreensaver.vue"),
  },
  {
    path: "/leavePage",
    name: "leavePage",
    component: () => import("../views/leavePage.vue"),
  },
  {
    path: "/leaveData",
    name: "leaveData",
    component: () => import("../views/leaveData.vue"),
  },
  {
    path: "/superscreen",
    name: "superscreen",
    component: () => import("../views/superscreen.vue"),
  },
  {
    path: "/addsuperscreen",
    name: "addsuperscreen",
    component: () => import("../views/addsuperscreen.vue"),
  },
  {
    path: "/leaveLog",
    name: "leaveLog",
    component: () => import("../views/leaveLog.vue"),
  },
  {
    path: "/homepageleaveLog",
    name: "homepageleaveLog",
    component: () => import("../views/homepageleaveLog.vue"),
  },
  {
    path: "/patriarchbinding",
    name: "patriarchbinding",
    component: () => import("../views/patriarchbinding.vue"),
  },
  {
    path: "/studentbinding",
    name: "studentbinding",
    component: () => import("../views/studentbinding.vue"),
  },
  {
    path: "/bindingpages",
    name: "bindingpages",
    component: () => import("../views/bindingpages.vue"),
  },
  {
    path: "/classCurriculum",
    name: "classCurriculum",
    component: () => import("../views/classCurriculum.vue"),
  },
  {
    path: "/curricuData",
    name: "curricuData",
    component: () => import("../views/curricuData.vue"),
  },
  {
    path: "/leavemessage",
    name: "leavemessage",
    component: () => import("../views/leavemessage.vue"),
  },
  {
    path: "/leavemessagepages",
    name: "leavemessagepages",
    component: () => import("../views/leavemessagepages.vue"),
  },
  {
    path: "/leaveApplication",
    name: "leaveApplication",
    component: () => import("../views/leaveApplication.vue"),
  },
  {
    path: "/leaveeLog",
    name: "leaveeLog",
    component: () => import("../views/leaveeLog.vue"),
  },
  {
    path: "/leavelogData",
    name: "leavelogData",
    component: () => import("../views/leavelogData.vue"),
  },
  {
    path: "/parentsPage",
    name: "parentsPage",
    component: () => import("../views/parentsPage.vue"),
  },
  {
    path: "/parentstag",
    name: "parentstag",
    component: () => import("../views/parentstag.vue"),
  },
  {
    path: "/parentsmassage",
    name: "parentsmassage",
    component: () => import("../views/parentsmassage.vue"),
  },
  {
    path: "/parentsmassageDetail",
    name: "parentsmassageDetail",
    component: () => import("../views/parentsmassageDetail.vue"),
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    component: () => import("../views/personalCenter.vue"),
  },
  {
    path: "/exchangePage",
    name: "exchangePage",
    component: () => import("../views/exchangePage.vue"),
  },
  {
    path: "/editPassword",
    name: "editPassword",
    component: () => import("../views/editPassword.vue"),
  },
  {
    path: "/newPassword",
    name: "newPassword",
    component: () => import("../views/newPassword.vue"),
  },
  {
    path: "/newpatriarchbinding",
    name: "newpatriarchbinding",
    component: () => import("../views/newpatriarchbinding.vue"),
  },
  {
    path: "/newstudentbinding",
    name: "newstudentbinding",
    component: () => import("../views/newstudentbinding.vue"),
  },
  {
    path: "/restaurantLeave",
    name: "restaurantLeave",
    component: () => import("../views/restaurantLeave.vue"),
  },
  {
    path: "/teachApply",
    name: "teachApply",
    component: () => import("../views/teacherpages/teachApply.vue"),
  },
  {
    path: "/restaurantLeavelist",
    name: "restaurantLeavelist",
    component: () => import("../views/restaurantLeavelist.vue"),
  },
  {
    path: "/teacherhome",
    name: "teacherhome",
    component: () => import("../views/teacherpages/teacherhome.vue"),
  },
  {
    path: "/applyforpages",
    name: "applyforpages",
    component: () => import("../views/teacherpages/applyforpages.vue"),
  },
  {
    path: "/backloginformation",
    name: "backloginformation",
    component: () => import("../views/teacherpages/backloginformation.vue"),
  },
  {
    path: "/examinepage",
    name: "examinepage",
    component: () => import("../views/teacherpages/examinepage.vue"),
  },
  {
    path: "/pendingexamine",
    name: "pendingexamine",
    component: () => import("../views/teacherpages/pendingexamine.vue"),
  },
  {
    path: "/homepagependingexamine",
    name: "homepagependingexamine",
    component: () => import("../views/teacherpages/homepagependingexamine.vue"),
  },
  {
    path: "/replacementCards",
    name: "replacementCards",
    component: () => import("../views/teacherpages/replacementCards.vue"),
  },
  {
    path: "/processed",
    name: "processed",
    component: () => import("../views/teacherpages/processed.vue"),
  },
  {
    path: "/makemy",
    name: "makemy",
    component: () => import("../views/teacherpages/makemy.vue"),
  },
  {
    path: "/teachapplyLog",
    name: "teachapplyLog",
    component: () => import("../views/teacherpages/teachapplyLog.vue"),
  },
  {
    path: "/myteachapplyLog",
    name: "myteachapplyLog",
    component: () => import("../views/teacherpages/myteachapplyLog.vue"),
  },
  {
    path: "/teachapplyLogdetail",
    name: "teachapply Logdetail",
    component: () => import("../views/teacherpages/teachapplyLogdetail.vue"),
  },
  {
    path: "/homepageteachapplyLogdetail",
    name: "homepageteachapplyLogdetail",
    component: () =>
      import("../views/teacherpages/homepageteachapplyLogdetail.vue"),
  },
  {
    path: "/myAattendance",
    name: "myAattendance",
    component: () => import("../views/teacherpages/myAattendance.vue"),
  },
  {
    path: "/myAattendancelist",
    name: "myAattendancelist",
    component: () => import("../views/teacherpages/myAattendancelist.vue"),
  },
  {
    path: "/myAattendancetemplist",
    name: "myAattendancetemplist",
    component: () => import("../views/teacherpages/myAattendancetemplist.vue"),
  },
  {
    path: "/myAattendancetemplistme",
    name: "myAattendancetemplistme",
    component: () =>
      import("../views/teacherpages/myAattendancetemplistme.vue"),
  },
  {
    path: "/studentbindingpages",
    name: "studentbindingpages",
    component: () => import("../views/studentbindingpages.vue"),
  },
  {
    path: "/checkingin",
    name: "checkingin",
    component: () => import("../views/teacherpages/checkingin.vue"),
  },
  {
    path: "/checkinginpage",
    name: "checkinginpage",
    component: () => import("../views/teacherpages/checkinginpage.vue"),
  },
  {
    path: "/checkrulesList",
    name: "checkrulesList",
    component: () => import("../views/teacherpages/checkrulesList.vue"),
  },
  {
    path: "/DormitorysStag",
    name: "DormitorysStag",
    component: () => import("../views/dormitorypages/DormitorysStag.vue"),
  },
  {
    path: "/bedroomPage",
    name: "bedroomPage",
    component: () => import("../views/dormitorypages/bedroomPage.vue"),
  },
  {
    path: "/Dormitorytypepage",
    name: "Dormitorytypepage",
    component: () => import("../views/dormitorypages/Dormitorytypepage.vue"),
  },
  {
    path: "/Dormitorytype",
    name: "Dormitorytype",
    component: () => import("../views/dormitorypages/Dormitorytype.vue"),
  },
  {
    path: "/buildingManger",
    name: "buildingManger",
    component: () => import("../views/dormitorypages/buildingManger.vue"),
  },
  {
    path: "/checkinpage",
    name: "checkinpage",
    component: () => import("../views/dormitorypages/checkinpage.vue"),
  },
  {
    path: "/checkinbatch",
    name: "checkinbatch",
    component: () => import("../views/dormitorypages/checkinbatch.vue"),
  },
  {
    path: "/checkinbatchpar",
    name: "checkinbatchpar",
    component: () => import("../views/dormitorypages/checkinbatchpar.vue"),
  },
  {
    path: "/dormitorypar",
    name: "dormitorypar",
    component: () => import("../views/dormitorypages/dormitorypar.vue"),
  },
  {
    path: "/addbuildingPage",
    name: "addbuildingPage",
    component: () => import("../views/dormitorypages/addbuildingPage.vue"),
  },
  {
    path: "/checkinDetail",
    name: "checkinDetail",
    component: () => import("../views/dormitorypages/checkinDetail.vue"),
  },
  {
    path: "/checkinDetailpage",
    name: "checkinDetailpage",
    component: () => import("../views/dormitorypages/checkinDetailpage.vue"),
  },
  {
    path: "/checkinStatistics",
    name: "checkinStatistics",
    component: () => import("../views/dormitorypages/checkinStatistics.vue"),
  },
  {
    path: "/chamberDetail",
    name: "chamberDetail",
    component: () => import("../views/dormitorypages/chamberDetail.vue"),
  },
  {
    path: "/checkinstudentpage",
    name: "checkinstudentpage",
    component: () => import("../views/dormitorypages/checkinstudentpage.vue"),
  },
  {
    path: "/checkinstudent",
    name: "checkinstudent",
    component: () => import("../views/dormitorypages/checkinstudent.vue"),
  },
  {
    path: "/checkindormitory",
    name: "checkindormitory",
    component: () => import("../views/dormitorypages/checkindormitory.vue"),
  },
  {
    path: "/dormitoryManagementt",
    name: "dormitoryManagementt",
    component: () => import("../views/dormitorypages/dormitoryManagementt.vue"),
  },
  {
    path: "/bedadministration",
    name: "bedadministration",
    component: () => import("../views/dormitorypages/bedadministration.vue"),
  },
  {
    path: "/adddormitoryManagementt",
    name: "adddormitoryManagementt",
    component: () =>
      import("../views/dormitorypages/adddormitoryManagementt.vue"),
  },
  {
    path: "/safariplanepage",
    name: "safariplanepage",
    component: () => import("../views/dormitorypages/safariplanepage.vue"),
  },
  {
    path: "/safariplane",
    name: "safariplane",
    component: () => import("../views/dormitorypages/safariplane.vue"),
  },
  {
    path: "/gatemachinepage",
    name: "gatemachinepage",
    component: () => import("../views/dormitorypages/gatemachinepage.vue"),
  },
  {
    path: "/gatemachine",
    name: "gatemachine",
    component: () => import("../views/dormitorypages/gatemachine.vue"),
  },
  {
    path: "/checkoutallpage",
    name: "checkoutallpage",
    component: () => import("../views/dormitorypages/checkoutallpage.vue"),
  },
  {
    path: "/checkoutclasspage",
    name: "checkoutclasspage",
    component: () => import("../views/dormitorypages/checkoutclasspage.vue"),
  },
  {
    path: "/checkoutclass",
    name: "checkoutclass",
    component: () => import("../views/dormitorypages/checkoutclass.vue"),
  },
  {
    path: "/checkoutdormitorypage",
    name: "checkoutdormitorypage",
    component: () =>
      import("../views/dormitorypages/checkoutdormitorypage.vue"),
  },
  {
    path: "/checkoutdormitory",
    name: "checkoutdormitory",
    component: () => import("../views/dormitorypages/checkoutdormitory.vue"),
  },
  {
    path: "/checkoutstudentpage",
    name: "checkoutstudentpage",
    component: () => import("../views/dormitorypages/checkoutstudentpage.vue"),
  },
  {
    path: "/checkoutstudent",
    name: "checkoutstudent",
    component: () => import("../views/dormitorypages/checkoutstudent.vue"),
  },
  {
    path: "/maypages",
    name: "maypages",
    component: () => import("../views/dormitorypages/maypages.vue"),
  },
  {
    path: "/bedroomNotice",
    name: "bedroomNotice",
    component: () => import("../views/dormitorypages/bedroomNotice.vue"),
  },
  {
    path: "/addbedroomNotice",
    name: "addbedroomNotice",
    component: () => import("../views/dormitorypages/addbedroomNotice.vue"),
  },
  {
    path: "/mayPagese",
    name: "mayPagese",
    component: () => import("../views/dormitorypages/mayPagese.vue"),
  },
  {
    path: "/classroomIndex",
    name: "classroomIndex",
    component: () => import("../views/smartClassroom/classroomIndex.vue"),
  },
  {
    path: "/classroomStrategy",
    name: "classroomStrategy",
    component: () => import("../views/smartClassroom/classroomStrategy.vue"),
  },
  {
    path: "/classroomData",
    name: "classroomData",
    component: () => import("../views/smartClassroom/classroomData.vue"),
  },
  {
    path: "/addPolicy",
    name: "addPolicy",
    component: () => import("../views/smartClassroom/addPolicy.vue"),
  },
  {
    path: "/timingPage",
    name: "timingPage",
    component: () => import("../views/smartClassroom/timingPage.vue"),
  },
  {
    path: "/repeatPage",
    name: "repeatPage",
    component: () => import("../views/smartClassroom/repeatPage.vue"),
  },
  {
    path: "/deletePolicy",
    name: "deletePolicy",
    component: () => import("../views/smartClassroom/deletePolicy.vue"),
  },
  {
    path: "/classroomOnly",
    name: "classroomOnly",
    component: () => import("../views/smartClassroom/classroomOnly.vue"),
  },
  {
    path: "/classroomDevice",
    name: "classroomDevice",
    component: () => import("../views/smartClassroom/classroomDevice.vue"),
  },
  {
    path: "/classroomChoice",
    name: "classroomChoice",
    component: () => import("../views/smartClassroom/classroomChoice.vue"),
  },
  {
    path: "/classroomCreateStrategy",
    name: "classroomCreateStrategy",
    component: () =>
      import("../views/smartClassroom/classroomCreateStrategy.vue"),
  },
  {
    path: "/classroomDetail",
    name: "classroomDetail",
    component: () => import("../views/smartClassroom/classroomDetail.vue"),
  },
  {
    path: "/training",
    name: "training",
    component: () => import("../views/trainingroom/training.vue"),
  },
  {
    path: "/insertequipment",
    name: "insertequipment",
    component: () => import("../views/trainingroom/insertequipment.vue"),
  },
  {
    path: "/insertequipmentt",
    name: "insertequipmentt",
    component: () => import("../views/trainingroom/insertequipmentt.vue"),
  },
  {
    path: "/equipmentOverview",
    name: "equipmentOverview",
    component: () => import("../views/trainingroom/equipmentOverview.vue"),
  },
  {
    path: "/minepage",
    name: "minepage",
    component: () => import("../views/trainingroom/minepage.vue"),
  },
  {
    path: "/training_equipment",
    name: "training_equipment",
    component: () => import("../views/trainingroom/training_equipment.vue"),
  },
  {
    path: "/training_statistics",
    name: "training_statistics",
    component: () => import("../views/trainingroom/training_statistics.vue"),
  },
  {
    path: "/training_my",
    name: "training_my",
    component: () => import("../views/trainingroom/training_my.vue"),
  },
  {
    path: "/correlationpage",
    name: "correlationpage",
    component: () => import("../views/trainingroom/correlationpage.vue"),
  },
  {
    path: "/trainingManage",
    name: "trainingManage",
    component: () => import("../views/trainingroom/trainingManage.vue"),
  },
  {
    path: "/trainingManagedetail",
    name: "trainingManagedetail",
    component: () => import("../views/trainingroom/trainingManagedetail.vue"),
  },
  {
    path: "/faultPage",
    name: "faultPage",
    component: () => import("../views/trainingroom/faultPage.vue"),
  },
  {
    path: "/repairreportList",
    name: "repairreportList",
    component: () => import("../views/trainingroom/repairreportList.vue"),
  },
  {
    path: "/equipmentOvervieww",
    name: "equipmentOvervieww",
    component: () => import("../views/trainingroom/equipmentOvervieww.vue"),
  },
  {
    path: "/repair_details",
    name: "repair_details",
    component: () => import("../views/trainingroom/repair_details.vue"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../views/trainingroom/maintenance.vue"),
  },
  {
    path: "/maintain_details",
    name: "maintain_details",
    component: () => import("../views/trainingroom/maintain_details.vue"),
  },
  {
    path: "/equipment_list",
    name: "equipment_list",
    component: () => import("../views/trainingroom/equipment_list.vue"),
  },
  {
    path: "/mymaintain",
    name: "mymaintain",
    component: () => import("../views/trainingroom/mymaintain.vue"),
  },
  {
    path: "/repair_detailstwo",
    name: "repair_detailstwo",
    component: () => import("../views/trainingroom/repair_detailstwo.vue"),
  },
  {
    path: "/mymaintenance",
    name: "mymaintenance",
    component: () => import("../views/trainingroom/mymaintenance.vue"),
  },
  {
    path: "/newrepair_detailstwo",
    name: "newrepair_detailstwo",
    component: () => import("../views/trainingroom/newrepair_detailstwo.vue"),
  },
  {
    path: "/maintain_list",
    name: "maintain_list",
    component: () => import("../views/trainingroom/maintain_list.vue"),
  },
  {
    path: "/maintenance_records",
    name: "maintenance_records",
    component: () => import("../views/trainingroom/maintenance_records.vue"),
  },
  {
    path: "/announcement",
    name: "announcement",
    component: () => import("../views/announcement.vue"),
  },
  {
    path: "/maintenance_list",
    name: "maintenance_list",
    component: () => import("../views/trainingroom/maintenance_list.vue"),
  },
  {
    path: "/maintenance_details",
    name: "maintenance_details",
    component: () => import("../views/trainingroom/maintenance_details.vue"),
  },
  {
    path: "/service_list",
    name: "service_list",
    component: () => import("../views/trainingroom/service_list.vue"),
  },
  {
    path: "/service_details",
    name: "service_details",
    component: () => import("../views/trainingroom/service_details.vue"),
  },
  {
    //维修记录详情
    path: "/details_repair",
    name: "details_repair",
    component: () => import("../views/trainingroom/details_repair.vue"),
  },
  {
    //保养记录详情
    path: "/details_maintenance",
    name: "details_maintenance",
    component: () => import("../views/trainingroom/details_maintenance.vue"),
  },
  // 设备名称
  {
    path: "/devicenameshow",
    name: "devicenameshow",
    component: () => import("../views/trainingroom/devicenameshow.vue"),
  },
  // 故障上报
  {
    path: "/fault_repair",
    name: "fault_repair",
    component: () => import("../views/trainingroom/fault_repair.vue"),
  },
  // 设备详情
  {
    path: "/fault_details",
    name: "fault_details",
    component: () => import("../views/trainingroom/fault_details.vue"),
  },
  {
    path: "/approveList2",
    name: "approveList2",
    component: () => import("../views/approveList2.vue"),
  },
  {
    path: "/myapproveList",
    name: "myapproveList",
    component: () => import("../views/myapproveList.vue"),
  },
  {
    path: "/banpaialarmList",
    name: "banpaialarmList",
    component: () => import("../views/banpaialarmList.vue"),
  },
  {
    path: "/trainingroomalarmList",
    name: "trainingroomalarmList",
    component: () => import("../views/trainingroomalarmList.vue"),
  },
  {
    path: "/repairpage",
    name: "repairpage",
    component: () => import("../views/trainingroom/repairpage.vue"),
  },
  {
    path: "/InventoryAlert",
    name: "InventoryAlert",
    component: () => import("../views/InventoryAlert.vue"),
  },
  {
    path: "/accessoriespage",
    name: "accessoriespage",
    component: () => import("../views/trainingroom/accessoriespage.vue"),
  },
  {
    path: "/ProcurementList",
    name: "ProcurementList",
    component: () => import("../views/trainingroom/ProcurementList.vue"),
  },
  {
    path: "/accessoriespage_detail",
    name: "accessoriespage_detail",
    component: () => import("../views/trainingroom/accessoriespage_detail.vue"),
  },
  {
    path: "/procurementApproval",
    name: "procurementApproval",
    component: () => import("../views/trainingroom/procurementApproval.vue"),
  },
  {
    path: "/procurementApproval_detail",
    name: "procurementApproval_detail",
    component: () =>
      import("../views/trainingroom/procurementApproval_detail.vue"),
  },
  {
    path: "/procurementApproval_details",
    name: "procurementApproval_details",
    component: () =>
      import("../views/trainingroom/procurementApproval_details.vue"),
  },
  {
    path: "/procurementApproval_log",
    name: "procurementApproval_log",
    component: () =>
      import("../views/trainingroom/procurementApproval_log.vue"),
  },
  {
    path: "/teachapplyLogdetail_copy",
    name: "teachapplyLogdetail_copy",
    component: () =>
      import("../views/teacherpages/teachapplyLogdetail_copy.vue"),
  },
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("../views/subscribe.vue"),
  },
  // 立达信-数字教室
  {
    path: "/classroomindexs",
    name: "classroomindexs",
    component: () => import("../views/leedarson/classroomindexs.vue"),
  },
  {
    path: "/classroomDevices",
    name: "classroomDevices",
    component: () => import("../views/leedarson/classroomDevices.vue"),
  },
  {
    path: "/classroomOnlys",
    name: "classroomOnlys",
    component: () => import("../views/leedarson/classroomOnlys.vue"),
  },
  {
    path: "/deletepolicys",
    name: "deletepolicys",
    component: () => import("../views/leedarson/deletepolicys.vue"),
  },
  {
    path: "/classroomStrategys",
    name: "classroomStrategys",
    component: () => import("../views/leedarson/classroomStrategys.vue"),
  },
  {
    path: "/addPolicys",
    name: "addPolicys",
    component: () => import("../views/leedarson/addPolicys.vue"),
  },
  {
    path: "/classroomCreateStrategys",
    name: "classroomCreateStrategys",
    component: () => import("../views/leedarson/classroomCreateStrategys.vue"),
  },
  {
    path: "/timingPages",
    name: "timingPages",
    component: () => import("../views/leedarson/timingPages.vue"),
  },
  {
    path: "/classroomChoices",
    name: "classroomChoices",
    component: () => import("../views/leedarson/classroomChoices.vue"),
  },
  {
    path: "/device_selections",
    name: "device_selections",
    component: () => import("../views/leedarson/device_selections.vue"),
  },
  {
    path: "/repeatPages",
    name: "repeatPages",
    component: () => import("../views/leedarson/repeatPages.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
});

const moduleMenufuns = async () => {
  const { data }: any = await moduleMenu({});
  moduleMenufun(data.data);
};
router.beforeEach(async (to: any, from: any, next: any) => {
  document.title = "数字校园";
  console.log("路由", to.name);
  if (
    to.name != "about" &&
    to.name != "newpatriarchbinding" &&
    to.name != "newstudentbinding" &&
    to.name != "subscribe" &&
    to.name != "home"
  ) {
    moduleMenufuns();
    const { data }: any = await permission({});
    authorityfun(data.data);
  }
  next();
});
router.afterEach((to: any, from: any) => {});

export default router;
