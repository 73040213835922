<template>
  <div class="content">
    <div class="p-r">
      <!-- <van-search
        v-model="value"
        show-action
        placeholder="请输入您需要查询的内容"
        @search="onSearch"
        shape="round"
      >
        <template #action>
        </template>
      </van-search> -->
      <img class="swiper" src="../assets/index_bg.png" />
    </div>
    <div class="total_number j-b f40 d-f p-r">
      <div class="c99 f24">
        <div class="f-w">{{ newdatas?.studentCount }}</div>
        <div>在校总人数</div>
      </div>
      <div class="c99 f24">
        <div class="f-w">{{ newdatas?.teacherCount }}</div>
        <div>教职工人数</div>
      </div>
      <div class="c99 f24">
        <div class="f-w">{{ newdatas?.roomCount }}</div>
        <div>教室总数</div>
      </div>
      <div class="c99 f24">
        <div class="f-w">{{ newdatas?.buildCount }}</div>
        <div>教学楼总数</div>
      </div>
    </div>
    <div class="module_ d-f c33 f24 p10">
      <div
        v-for="item in oftenmodel"
        :key="item"
        class="w25"
        v-show="moduleMenutf(item?.authority)"
        @click="routersta(item?.strs)"
      >
        <!-- 数字后勤 -->
        <img
          v-if="item?.strs == 'smartLogistics'"
          src="../assets/module2.png"
          alt=""
        />
        <!-- 电子班牌 -->
        <img
          v-if="item?.strs == 'classCard'"
          src="../assets/module6.png"
          alt=""
        />
        <!-- 教职工 -->
        <img
          v-if="item?.strs == 'teacherhome'"
          src="../assets/teacher.png"
          alt=""
        />
        <!-- 归寝管理 -->
        <img
          v-if="item?.strs == 'bedroomPage'"
          src="../assets/module3.png"
          alt=""
        />
        <!-- 数字教室 -->
        <img
          v-if="item?.strs == 'classroomIndex'"
          src="../assets/module7.png"
          alt=""
        />
        <!-- 数字实训室 -->
        <img
          v-if="item?.strs == 'training'"
          src="../assets/module1.png"
          alt=""
        />
        <!-- AI助手 -->
        <img
          v-if="item?.strs == '/api/app/ai'"
          src="../assets/aizhushou.png"
          alt=""
        />
        <div>{{ item?.text }}</div>
      </div>
      <div class="w25" @click="handleadd">
        <img src="../assets/background/module4.png" alt="" />
        <div>更多应用</div>
      </div>
    </div>
    <div
      v-if="authoritytf('admin.warehouse.warehousenotify.select')"
      class="swperr"
      @click="handelegonggao"
    >
      <img src="../assets/gonggao.png" alt="" />
      <van-divider vertical dashed :style="{ borderColor: '#000000' }" />
      <van-swipe
        class="my-swipe"
        indicator-color="#247AF1"
        vertical
        :autoplay="3000"
      >
        <template v-if="moduleMenutf('warehouse')">
          <van-swipe-item
            style="position: relative"
            v-for="(item, index) in warenoticelist"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>{{ item.created_at }}</p>
          </van-swipe-item>
        </template>
        <template v-if="moduleMenutf('dorm')"
          ><van-swipe-item
            style="position: relative"
            v-for="(item, index) in dromnoticelist"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>{{ item.created_at }}</p>
          </van-swipe-item></template
        >
        <template v-if="moduleMenutf('ecard')"
          ><van-swipe-item
            style="position: relative"
            v-for="(item, index) in ecarnoticelist"
            :key="index"
          >
            <p>{{ item.title }}</p>
            <p>{{ item.created_at }}</p>
          </van-swipe-item></template
        >
      </van-swipe>
    </div>
    <div class="d-f j-b module">
      <div @click="waittobedonesta" class="module_ d-f">
        <img src="../assets/index1.png" alt="" />
        <div>
          <div class="c33 f30">我的待办</div>
          <div class="c99 f24">查看全部待办信息</div>
        </div>
      </div>
      <div
        v-if="authoritytf('admin.warehouse.warehouseapply.select')"
        @click="handleapplyforList"
        class="module_ d-f"
      >
        <img src="../assets/index2.png" alt="" />
        <div>
          <div class="c33 f30">我的申请</div>
          <div class="c99 f24">查看全部申请信息</div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab c99 f30 d-f">
        <div @click="tabsta(1)" :class="tab == 1 ? 'tab_ c33 f30 f-w p-r' : ''">
          校园风采
        </div>
        <div @click="tabsta(2)" :class="tab == 2 ? 'tab_ c33 f30 f-w p-r' : ''">
          班级风采
        </div>
      </div>
      <div v-if="tab == 1" class="campus t-l d-f j-b c33 f26">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in newdatas.shoolNews"
            ><img style="width: 100%" :src="'/api' + item?.url" alt=""
          /></van-swipe-item>
        </van-swipe>
      </div>
      <div v-if="tab == 2" class="campus t-l d-f j-b c33 f26">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="item in newdatas.classNews"
            ><img style="width: 100%" :src="'/api' + item?.url" alt=""
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div
      v-if="
        authoritytf('admin.generaltraining.generaltrainingdevicewarn.select') ||
        authoritytf('admin.ecard.ecard.select') ||
        authoritytf('admin.warehouse.warehousewarn.select')
      "
      class="list"
    >
      <div
        v-if="
          authoritytf('admin.generaltraining.generaltrainingdevicewarn.select')
        "
        class="p-r"
        @click="routersta('trainingroomalarmList')"
      >
        <img src="../assets/index3.png" alt="" />
        <div class="cff f24 p-a">实训室预警</div>
        <div class="f32 cfc p-a">实训室报警:{{ trainingroomcount }}</div>
        <div class="c33 f24 p-a">实训室设备故障报警数量</div>
      </div>
      <div
        v-if="authoritytf('admin.ecard.ecard.select')"
        class="p-r"
        @click="routersta('banpaialarmList')"
      >
        <img src="../assets/index4.png" alt="" />
        <div class="cff f24 p-a" style="background: #247af1">班牌预警</div>
        <div style="color: #247af1" class="f32 cfc p-a">
          班牌预警:{{ banpaicount }}
        </div>
        <div class="c33 f24 p-a">班牌离线预警信息查询</div>
      </div>
      <div
        v-if="authoritytf('admin.warehouse.warehousewarn.select')"
        class="p-r"
        @click="routersta('alarmList')"
      >
        <img src="../assets/index5.png" alt="" />
        <div style="background-color: #52d0b1" class="cff f24 p-a">
          仓储预警
        </div>
        <div style="color: #52d0b1" class="f32 cfc p-a">
          仓储预警:{{ warningcount }}
        </div>
        <div class="c33 f24 p-a">工具数量、种类多拿信息查询</div>
      </div>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/staGing">
        <span>工作台</span>
        <template #icon="props">
          <img :src="props.active ? icontab2.active : icontab2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/myCenter">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icontab3.active : icontab3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script lang="ts" setup>
import { moduleMenutf, authoritytf } from "@/utils/common";
import { ref, onMounted, onBeforeMount } from "vue";
import router from "@/router";
const warenoticelist = ref([]); //仓储
const dromnoticelist = ref([]); //归寝通告
const ecarnoticelist = ref([]); //班牌
const msglist = ref([]);
const mymessagetwo = ref<any>();
const tab = ref(1);
import {
  notice,
  mymessage,
  schooldata,
  checkUser,
  moduleMenu,
  permission,
  main,
  generalTrainingdeviceWarnselect,
  ecardselect,
  warningSelect,
} from "@/axios/index";
import { authorityfun, moduleMenufun } from "@/utils/common";
import Newrepair_detailstwo from "./trainingroom/newrepair_detailstwo.vue";
const tabsta = (val: any) => {
  tab.value = val;
};
const routersta = (val: any) => {
  if (val == "/api/app/ai") {
    window.open(val);
  } else {
    router.push({
      path: val,
    });
  }
};
const newdatas = ref<object>([]);
const mains = async () => {
  const { data }: any = await main({});
  if (data.code == 0) {
    newdatas.value = data.data;
    warenoticelist.value = data.data?.wareHouseNotifys;
    dromnoticelist.value = data.data?.dormNotifys;
    ecarnoticelist.value = data.data?.ecardNotifys;
  }
};
// 我的待办
const waittobedonesta = () => {
  router.push({
    path: "./approveList",
  });
};
// 我的申请
const handleapplyforList = () => {
  router.push({
    path: "./myapproveList",
  });
};
//写接口
// 登陆检测
const checkUsers = async () => {
  let { data }: any = await checkUser({});
  const newdata = ref<any>(data.data);
  console.log("登陆检测", data.data.need_login, data.data.need_login == false);
  if (data.data.need_login == false) {
    console.log("不需要登陆", data.data.apiadmin.account_type);
    if (data.data.apiadmin.account_type == 3) {
      console.log("身份家长", data.data.apiadmin.account_type);
      router.push({
        path: "./parentsPage",
      });
    } else {
      if (
        localStorage.getItem("oftenmoudeldata") == null ||
        localStorage.getItem("oftenmoudeldata") == "[]"
      ) {
        oftenmodel.value = [];
      } else {
        oftenmodel.value = [];
        let newoftenmodel = JSON.parse(localStorage.getItem("oftenmoudeldata"));
        const { data }: any = await moduleMenu({});
        let newdata = data.data.map((item: any) => {
          return item?.key;
        });
        newoftenmodel.forEach((item: any) => {
          if (newdata.includes(item.authority)) {
            oftenmodel.value.push(item);
            return;
          }
          if (item.authority == "/api/app/ai") {
            oftenmodel.value.push(item);
            return;
          }
        });
      }
      mains();
      const { data }: any = await moduleMenu({});
      moduleMenufun(data.data);
      const datas = await permission({});
      authorityfun(datas.data.data);
      localStorage.setItem("mymessage", JSON.stringify(newdata.value.apiadmin));
      mymessages();
      console.log("身份学校", newdata.value.apiadmin);
      if (
        authoritytf("admin.generaltraining.generaltrainingdevicewarn.select")
      ) {
        generalTrainingdeviceWarnselects();
      }
      if (authoritytf("admin.ecard.ecard.select")) {
        ecardselects();
      }
      if (authoritytf("admin.warehouse.warehousewarn.select")) {
        warningSelects();
      }
    }
  } else {
    console.log("需要登陆", data.data.need_login);
    localStorage.setItem("opneid", JSON.stringify(data.data.open_id));
    router.push({
      path: "./about",
    });
  }
};
// 我的消息
const mymessages = async () => {
  let { data } = await mymessage({});
  if (data.code == 0) {
    msglist.value = data.data;
  } else {
    alert(data.msg);
  }
};
// 实训室报警
const trainingroomcount = ref<number>(0);
const generalTrainingdeviceWarnselects = async () => {
  const { data }: any = await generalTrainingdeviceWarnselect({
    page: 1,
    limit: 10,
    event: 1,
  });
  if (data.code == 0) {
    trainingroomcount.value = data.count;
  }
};
// 电子班牌报警
const banpaicount = ref<number>(0);
const ecardselects = async () => {
  let { data } = await ecardselect({
    page: 1,
    limit: 10,
  });
  if (data.code == 0) {
    banpaicount.value = data.count;
  }
};
// 仓储报警
const warningcount = ref<number>(0);
const warningSelects = async () => {
  let { data } = await warningSelect({
    page: 1,
    limit: 10,
  });
  if (data.code == 0) {
    warningcount.value = data.count;
  }
};
const oftenmodel = ref<object>([]);
onBeforeMount(async () => {});
onMounted(() => {
  checkUsers();
  mymessagetwo.value = JSON.parse(localStorage.getItem("mymessage"));
});
// 动态css样式
const num = ref(1);
const border = ref(1);
// tabar
const active = ref(0);
const icon = {
  active: require("/src/assets/tab1.png"),
  inactive: require("/src/assets/tab1x.png"),
};
const icontab2 = {
  active: require("/src/assets/background/tab2.png"),
  inactive: require("/src/assets/background/tab2x.png"),
};
const icontab3 = {
  active: require("/src/assets/background/tab3.png"),
  inactive: require("/src/assets/background/tab3x.png"),
};
// 公告详情
const handelegonggao = () => {
  // router.push({
  //   path: "/noticeList",
  // });
  router.push({
    path: "/announcement",
  });
};
const handleapply = () => {
  router.push({
    path: "/applyforList",
  });
};
const handleapprove = () => {
  router.push({
    path: "/approveList",
  });
};
const handlewarehous = () => {
  router.push({
    path: "/smartLogistics",
  });
};
const handleadd = () => {
  router.push({
    path: "./staGing",
  });
};
</script>
<style lang="scss" scoped>
.w25 {
  width: 25%;
}
.p10 {
  padding: 20px 10px !important;
}
.content {
  padding-bottom: 150px;
}
::v-deep .van-search {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  padding-top: 40px;
  left: 0;
  width: 100%;
  .news {
    width: 40px;
    height: 40px;
  }
  .van-search__content {
    background-color: #fff;
  }
  .van-search__action {
    display: flex;
    align-items: center;
  }
}
.swiper {
  width: 100%;
  height: auto;
  border-radius: 0;
}
.total_number {
  z-index: 99;
  padding: 40px 50px;
  border-radius: 30px 30px 0 0;
  margin-top: -60px;
  background-color: #fff;
  .c99 {
    &:nth-child(1) {
      .f-w {
        color: #4dd7ab;
      }
    }
    &:nth-child(2) {
      .f-w {
        color: #ff9205;
      }
    }
    &:nth-child(3) {
      .f-w {
        color: #427df9;
      }
    }
    &:nth-child(4) {
      .f-w {
        color: #8278f1;
      }
    }
    .f-w {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
}
.module_ {
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 50px 30px 50px;
  img {
    width: 110px;
    height: 110px;
    margin-bottom: 10px;
  }
}
.swperr {
  padding: 30px 0;
  display: flex;
  height: 100px;
  background-color: #ffffff;
  margin-top: 20px;
}
.van-swipe-item {
  width: 500px;
}
.swperr img {
  text-align: left;
  width: 12%;
  height: 100px;
  margin-left: 30px;
  margin-right: 30px;
}
.my-swipe .van-swipe-item {
  width: 640px;
}
.swperr .van-divider {
  height: 100px;
  width: 5px;
}
::v-deep .van-swipe__indicators {
  position: absolute;
  bottom: 45px;
  // left: 525px;
}
.van-swipe-item p {
  color: #000000;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  font-size: 30px;
  font-weight: 600;
  margin-left: -166px;
  margin-top: 10px;
  width: 480px;
  top: 0;
  left: 172px;
  position: absolute;
  padding-right: 15px;
}
.van-swipe-item p:nth-child(2) {
  position: absolute;
  font-weight: 500;
  top: 52px;
  left: 170px;
  color: #999999;
}
.module {
  background-color: #fff;
  padding: 30px;
  text-align: left;
  margin-top: 20px;
  .module_ {
    width: 335px;
    background: #eff3ff;
    border-radius: 20px;
    padding: 30px 0;
    line-height: 45px;
  }
  img {
    width: 80px;
    height: 80px;
    margin-left: 27px;
    margin-right: 12px;
  }
}
.tabs {
  padding: 39px 30px;
  background-color: #fff;
  margin-top: 20px;
  .tab {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
    div {
      margin-right: 53px;
    }
    .tab_ {
      &::after {
        content: "";
        width: 40px;
        height: 5px;
        background: #247af1;
        position: absolute;
        left: 50%;
        bottom: -20px;
        margin-left: -20px;
      }
    }
  }
  .campus {
    margin-top: 30px;
    img {
      width: 335px;
      height: 350px;
      border-radius: 10px;
      // margin-bottom: 20px;
    }
  }
}
.list {
  margin-top: 20px;
  background-color: #fff;
  padding: 40px 30px;
  .p-r {
    margin-bottom: 20px;
    &:nth-child(3) {
      margin-bottom: 0;
    }
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  .cff {
    left: 0;
    top: 0;
    background-color: #ff656d;
    width: 150px;
    height: 46px;
    line-height: 46px;
    border-radius: 20px 4px 20px 0px;
  }
  .cfc {
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
  .c33 {
    top: 105px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
</style>
