<template>
  <router-view />
</template>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html,
body {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
}
i {
  display: inline-block;
  font-style: normal;
}
ul,
li {
  list-style: none;
}
// 空判断
.uncontent {
  padding: 274px 200px 42px 200px;
  box-sizing: border-box;
  .uncontent-font {
    width: 100%;
    height: 28px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #cccccc;
    line-height: 44px;
    margin-top: 42px;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
    box-sizing: border-box;
  }
}
.ps {
  height: 60px;
  line-height: 60px;
  font-weight: 800;
  text-align: left;
  margin-top: 10px;
  box-sizing: border-box;
  font-size: 28px;
  .biao {
    display: inline-block;
    width: 10px;
    height: 30px;
    line-height: 60px;
    background-color: #409eff;
    margin-right: 10px;
    margin-top: 10px;
  }
}
// 绿色
.green {
  color: green;
}
.bggreen {
  background-color: green;
}
// 红色
.red {
  color: red;
}
.bgred {
  background-color: red;
}
// 黄色
.orange {
  color: orange;
}
.bgorange {
  background-color: orange;
}
// 灰白色
.gray {
  color: #333333;
}
.bggray {
  background-color: gray;
}
// 蓝色
.blue {
  color: blue;
}
.bgblue {
  background-color: blue;
}
.qianblue {
  color: #2d93fa;
}
.bgqianblue {
  color: #2d93fa;
}
// 紫色
.zise {
  color: #8256e8;
}
.bgzise {
  background-color: #8256e8;
}
// 加载更多
.more {
  font-size: 30px;
  margin: 0;
  padding: 10px !important;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f22 {
  font-size: 22px;
}
.f24 {
  font-size: 24px;
}
.f26 {
  font-size: 26px;
}
.f28 {
  font-size: 28px;
}
.f30 {
  font-size: 30px;
}
.f32 {
  font-size: 32px;
}
.f34 {
  font-size: 34px;
}
.f36 {
  font-size: 36px;
}
.f40 {
  font-size: 40px;
}
.f44 {
  font-size: 44px;
}
.f46 {
  font-size: 46px;
}
.f70 {
  font-size: 70px;
}
.c66 {
  color: #666;
}
.c14 {
  color: #14c97c;
}
.c24 {
  color: #247af1;
}
.cff {
  color: #fff;
}
.cfc {
  color: #fc5e77;
}
.c99 {
  color: #999;
}
.c33 {
  color: #333;
}
.cb3 {
  color: #b3b7c2;
}
.ccc {
  color: #ccc;
}
.f-w {
  font-weight: bold;
}
.c6c {
  color: #cccccc;
}
.f-w {
  font-weight: bold;
}
.bd8 {
  background-color: #b8b8b8;
}
.b24 {
  background-color: #247af1;
}
.bff {
  background-color: #fff;
}
.d-f {
  display: flex;
  align-items: center;
}
.j-b {
  justify-content: space-between;
}
.j-a {
  justify-content: space-around;
}
.j-c {
  justify-content: center;
}
.p-r {
  position: relative;
}
.p-a {
  position: absolute;
}
.p-f {
  position: fixed;
}
.ov {
  overflow: hidden; // 超出的文本隐藏
  text-overflow: ellipsis; // 溢出用省略号显示
  white-space: nowrap; // 溢出不换行
}
.ov2 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mt-10 {
  margin-top: 10px;
}
.t-l {
  text-align: left;
}
.mt-20 {
  margin-top: 20px;
}
.t-l {
  text-align: left;
}
</style>
