import { ref } from "vue";
const moduleMenudata = ref<any>([]);
const authoritydata = ref<any>([]);
const authorityfun = (authorityds: any) => {
  authoritydata.value = authorityds;
};
const moduleMenufun = (moduleMenus: any) => {
  moduleMenudata.value = moduleMenus;
};
const authoritytf = (authorityd: any) => {
  return authoritydata.value?.filter((item: string) => {
    return item == authorityd;
  }).length;
};
const moduleMenutf = (moduleMenu: any) => {
  if (moduleMenu == "/api/app/ai") {
    return true;
  }
  return moduleMenudata.value?.filter((item: any) => {
    return item.key == moduleMenu;
  }).length;
};

export { authorityfun, authoritytf, moduleMenufun, moduleMenutf };
